<template>
  <div  class="mainform">
    <div class="form">
        <el-form :model="form" class="content bascform" label-width="80px" :disabled="true">
          <div class="col col4">
              <el-form-item label="行号" >
                <el-input v-model="form.orderRow"></el-input>
              </el-form-item>
          </div>
          <div class="col col4">
              <el-form-item label="物料编号" >
                <el-input v-model="form.materialCode"></el-input>
              </el-form-item>
          </div>
          <div class="col col4">
              <el-form-item label="物料描述" >
                <el-input v-model="form.materialName"></el-input>
              </el-form-item>
          </div>
    </el-form>
    <global-table :tableField="tableField"  max-height="250" :tableData="tableData"></global-table>
    </div>
  </div>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { tableField, tableData } from './js/reComponentListdialog'
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'ReComponentListdialog',
  props: ['showData'],
  components: { GlobalTable },
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      form: this.showData
    }
  },
  mounted () {
    this.ComponentList()
  },
  methods: {
    ComponentList () {
      var obj = {
        orderCode: this.$route.query.orderCode,
        orderRow: this.showData.orderRow
      }
      requestForm('/api/order/order/getOrderMatailInfoByOrderCode', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.tableData = response.data.orderMaterialInfo
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .mainform {
    .form {
    padding: 0px;
  }
  }

</style>
